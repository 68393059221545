import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal' // 模态框组件
import 'normalize.css/normalize.css';
import './assets/css/index.css'; // 全局样式

import './permission' // 全局路由拦截器
import VScaleScreen from 'v-scale-screen'
import globalStartSpeeModal from "@/components/GlobalStartSpeeModal";


Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dynamicDefaults: { clickToClose: true } })
Vue.use(VScaleScreen)

Vue.config.productionTip = false


const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
Vue.prototype.playSound = function (text, startCallBack, endCallBack) {
    if(!text) return
    synth.cancel()
    msg.lang = 'zh-CN'
    msg.text = text
    synth.speak(msg);
    msg.onstart = () => {
        console.log('onstart');
        if(startCallBack) {
            startCallBack()
        }
    }
    msg.onend = function (res) {
        console.log('onend=', res);
        if(endCallBack) {
            endCallBack()
        }
    }
    msg.onerror = function (e) {
        console.log('e=', e);
    }
}

new Vue({
    router,
    store,
    render: h => h(App),
    created() {

    },
    mounted() {
        this.$modal.show(globalStartSpeeModal, {

        }, {
            width: 400,
            height: 152,
        }, {

        })
    }
}).$mount('#app')
