const getters = {
    // 扎染法
    selectColorValue: state => state.prepareDey.selectColorValue,
    selectCateValue: state => state.prepareDey.selectCateValue,
    selectPlantValue: state => state.prepareDey.selectPlantValue,
    plantList: state => state.prepareDey.plantList,


    speech: state => state.prepareDey.speech,
    enableVoice: state => state.prepareDey.enableVoice,


}
export default getters
