import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from "@/router/routes";
Vue.use(VueRouter)



// import HomeView from '../views/page/TheHomeView.vue'
// // import IntroductionView from "@/views/page/introduction/IntroductionView";
// import CMRView from "@/views/CMRView";
// import ExpHome from "@/views/page/exp/ExpHome";

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'cmr',
//     component: CMRView,
//     children: [
//       {
//         path: '/',
//         name: 'home',
//         component: HomeView
//       },
//       {
//         path: '/introduction',
//         name: 'introduction',
//         component: IntroductionView
//       },
//       {
//         path: '/exp',
//         name: 'expHome',
//         component: ExpHome
//       }
//     ]
//   },
// ]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
