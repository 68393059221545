<template>
  <div>
    <header class="bf-header">
      <div class="bf-header-logo" @click="$router.push('/')">
        <img src="@/assets/global/logo.png" srcset="@/assets/global/logo@2x.png 2x"/>
      </div>
      <div class="bf-header-title">
        <div class="bf-header-title-cn">
          草木染交互程序
        </div>
        <div class="bf-header-title-en">
          PLANT DYEING INTERACTION PROGRAM
        </div>
      </div>
      <div class="bf-header-newTime">
        {{ nowTime | updateTime }}
      </div>
    </header>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('zh-cn')
export default {
  name: "headerView",
  data() {
    return {
      // 当前时间
      nowTime: new Date(),
      // 定时器
      timer: null
    }
  },
  filters: {
    updateTime: function (value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.nowTime = new Date();
    }, 1000)
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped lang="scss">
.bf-header {
  display: flex;
  align-items: center;
  //padding: 23px 0 37px 0;
 padding: 0 70px 0 59px;

  height: 160px;
  .bf-header-logo{
    img{
      cursor: pointer;
      width: 118px;
      height: auto;
    }
  }
  .bf-header-title{
    .bf-header-title-cn{
      font-size: 48px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1;
      margin-bottom: 7px;
    }
    .bf-header-title-en{
      font-weight: 400;
      color: #FFFFFF;
      opacity: 0.3;
      line-height: 1;
      font-size: 17px;
    }
  }
  .bf-header-newTime{
    flex: 1;
    text-align: right;
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;

  }
}

.newTime {
  font-size: 24px;
  font-weight: 500;
}
</style>
