<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
body{
  overflow: hidden;
}
// 进度条颜色
#nprogress .bar {
  background: #63FEFF !important;
}
</style>
>
