import LayoutView from '@/layout'

const routes = [
    {
        path: '',
        component: LayoutView,
        meta: {
            title: '草木染交互程序'
        },
        children: [{
            path: '',
            name: 'HomeView',
            component: () => import('@/views/home/TheHomeView'),
            meta: {
                title: '草木染交互程序'
            },
        }, {
            path: '/introduction',
            name: 'introduction',
            component: () => import('@/views/introduction/TheIntroductionView'),
            meta: {
                title: '何为草木染'
            },
        }, {
            path: '/exp',
            name: 'expHome',
            component: () => import('@/views/exp/TheExpView'),
            meta: {
                title: '染色体验'
            }
        }, {
            path: '/tie-dye-tool',
            name: 'tieDye',
            component: () => import('@/views/exp/TheTieDyeToolView'),
            meta: {
                title: '扎染工具选择'
            }
        }, {
            path: '/prepare',
            name: 'prepareDye',
            component: () => import('@/views/exp/ThePrepareDyeView'),
            meta: {
                title: '准备夹染法原料'
            }
        }, {

            path: '/coal-dye',
            name: 'coalDay',
            component: () => import('@/views/introduction/TheCoalDyeView'),
            meta: {
                title: '分类介绍'
            }
        }, {
            path: '/dye-select',
            name: 'daySelect',
            component: () => import('@/views/exp/TheDyeSelect'),
            meta: {
                title: '染料选择'
            }
        }, {
            path: '/select-fabric',
            name: 'selectFabric',
            component: () => import('@/views/exp/TheFabricSelectedView'),
            meta: {
                title: '选择产品'
            }
        }, {
            path: '/select-plant',
            name: 'selectPlant',
            component: () => import('@/views/exp/ThePlantSelectedView'),
            meta: {
                title: '选择植物'
            }
        }, {
            path: '/clip-dye',
            name: 'clipTry',
            component: () => import('@/views/dye/TheClipDyeBeginView'),
            meta: {
                title: '开始夹染'
            }
        }, {
            path: '/bundle-dye',
            name: 'bundleTry',
            component: () => import('@/views/dye/TheBundleDyeBeginView'),
            meta: {
                title: '开始扎染'
            }

        }, {
            path: '/three-real',
            name: 'threeReal',
            component: () => import('@/views/three-real/index'),
            meta: {
                title: '3d-效果展示'
            }
        }, {
            path: '/beat-dye',
            name: 'beatTry',
            component: () => import('@/views/dye/TheBeatDyeBeginView'),
            meta: {
                title: '开始敲染'
            }
        }, {
            path: '/dyeing',
            name: 'dyeing',
            component: () => import('@/views/dye/TheDyeingView'),
            meta: {
                title: '染缸染色'
            }
        }, {
            path: '/fixed',
            name: 'fixed',
            component: () => import('@/views/dye/TheFixedView'),
            meta: {
                title: '固化'
            }
        }]
    }
]

export default routes
