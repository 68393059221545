// 扎染
const state = {
    speech: false,
    enableVoice: true,
    plantList: [
        {'name': '洋桔梗', 'url': require('@/assets/exp/plant/eustoma.png')},
        {'name': '红鸡蛋花', 'url': require('@/assets/exp/plant/plumeriarubra.png')},
        {'name': '银杏叶', 'url': require('@/assets/exp/plant/ginkgo.png')},
        {'name': '万年青', 'url': require('@/assets/exp/plant/evergreen.png')},
        {'name': '女贞', 'url': require('@/assets/exp/plant/privet.png')},
        {'name': '酢浆草', 'url': require('@/assets/exp/plant/creeping.png')},
        {'name': '散尾葵', 'url': require('@/assets/exp/plant/bamboo.png')},
        {'name': '金鸡菊', 'url': require('@/assets/exp/plant/golden.png')},
        {'name': '显齿蛇葡萄', 'url': require('@/assets/exp/plant/ampelopsis.png')},
        {'name': '箭叶秋葵', 'url': require('@/assets/exp/plant/sagittifolius.png')},
        {'name': '朱槿', 'url': require('@/assets/exp/plant/hibiscus.png')},

        // {'name': '蕨类植物', 'url': require('@/assets/exp/plant/maple-leaf.jpg'), 'url2x': require('@/assets/exp/plant/maple-leaf.png')},
        // {'name': '菊花', 'url': require('@/assets/exp/plant/Ferns.jpg'), 'url2x': require('@/assets/exp/plant/Ferns.png')},
        // {'name': '香椿', 'url': require('@/assets/exp/plant/toon.jpg'), 'url2x': require('@/assets/exp/plant/toon.png')},
    ],
    selectColorValue: {
        colorTitle: "",
        colorValue: "",
        enName: ""
    },
    // 选择的织物
    selectCateValue: {
        image: '',
        name: '',
        id: ''
    },
    // 选择的植物
    selectPlantValue: {
        index: -1,
        value: ''
    },
}

const mutations = {
    SET_COLOR_VALUE: (state, data) => {
        state.selectColorValue = data
    },
    SET_CATE_VALUE: (state, data) => {
        state.selectCateValue = data;
    },
    SET_SPEECH: (state, data) => {
        state.speech = data
    },
    enableVoice: (state, data) => {
        state.enableVoice = data
    },
    SET_PLANT_VALUE: (state, data) => {
        state.selectPlantValue = data
    },
    SET_STATE_NULL(state, data) {
        state.selectColorValue = {
            colorTitle: "",
            colorValue: "",
            enName: ""
        }
        state.selectCateValue = ''
        state.selectPlantValue =  {
            index: -1,
            value: ''
        }
        console.log(data);
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
