<template>
  <div>
    <div id="custom-qr-attr">
      <div class="bf-qrcode-container">
        <div class="bf-qrcode-title">
          <div style="text-align: center;width: 100%">启动语音播报！</div>
<!--          <div>-->
<!--            <img src="@/assets/threeReal/guanbixiao@2x.png" @click="$emit('close')">-->
<!--          </div>-->
        </div>
        <div class="bf-qrcode-btn">
          <button @click="startSpeech">启动</button>
          <button @click="endSpeech">不启动</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GlobalStartSpeeModal",
  computed: {
    ...mapGetters([
      'isSpeech',
      'enableVoice'
    ]),
  },
  methods: {
    startSpeech() {
      console.log('startSpeech')
      this.playSound('欢迎体验草木染交互系统！', () => {
        this.$emit('close');
        this.$store.commit('prepareDey/SET_SPEECH', true)
      }, () => {
        this.$store.commit('prepareDey/SET_SPEECH', false)
      })
    },
    endSpeech() {
      this.$emit('close');
      this.$store.commit('prepareDey/enableVoice', false)
    }
  }
}
</script>

<style scoped lang="scss">

.bf-qrcode-container{
  background: #FFFFFF;
  border-radius: 30px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  .bf-qrcode-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    >div{
      font-size: 24px;
      font-weight: bold;
    }
    img{
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

  }
  .bf-qrcode-btn{
    margin-top: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    >button{
      width: 100px;
      height: 40px;
      line-height: 40px;
      border: 0;
      margin: 5px;
      background: #409eff;
      color: #fff;
      cursor: pointer;
    }
    button:nth-child(2) {
      background: #e6a23c;
    }
  }
  .bf-qrcode-content{
    display: flex;
    justify-content: center;
    align-items: center;

  }
}
</style>
