import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import prepareDey from './modules/prepare-dye'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        prepareDey
    },
    getters
})
export default store
