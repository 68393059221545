<template>
  <div>
      <div class="bf-app-wrapper">
        <v-scale-screen >
          <div class="bf-header-container">
            <HeaderView/>
          </div>
          <div class="bf-main-container">
            <AppMain/>
          </div>
        </v-scale-screen>
      </div>
  </div>

</template>

<script>
import HeaderView from "@/layout/components/TheHeaderView";
import AppMain from "@/layout/components/TheAppMain";

export default {
  name: "LayoutView",
  components: {AppMain, HeaderView}
}
</script>

<style scoped>
body{
  overflow: hidden;
}
.screen-box{
  background-image: url('@/assets/global/back-img@2x.png')!important;
  background-position: center center!important;
  background-size: 100% 100%!important;
  background-repeat: no-repeat!important;

}

.screen-box .screen-wrapper{
  margin: 0 !important;
}

.bf-app-wrapper {
  display: flex;
  /*  让元素沿垂直主轴从上到下垂直排列  */
  flex-direction: column;

}

.bf-main-container {
  flex: 1;
  padding: 0 50px 0 47px;
}

</style>
