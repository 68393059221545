<template>
  <section class="app-main">
    <router-view/>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main{
  height: 100%;
  width: 100%;
  position: relative;
  /*overflow: hidden;*/
  /*padding: 0 50px 0 47px;*/
}
</style>
